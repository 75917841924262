.App {
  text-align: center;
 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.breadcrumbs {
  display: flex;
  height: 40px;
  align-items: center;
}

.cropper-crop {
  border-radius: 8px;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.react-datepicker__header, .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
  background-color: #32B265 !important;
}
.react-datepicker-wrapper{
  width: 100% !important;
}
.react-datepicker__day-name{
  color: white !important;
}
.react-datepicker__month-select, .react-datepicker__year-select{
  height: 25px;
  border: none;
}
.react-datepicker__month-select, .react-datepicker__year-select:focus,
.react-datepicker__month-select, .react-datepicker__year-select:hover {
  outline: none;
  border:none;
}
.react-datepicker__header__dropdown{
  margin-top: 5px;
}
.react-datepicker__current-month{
  color:white !important;
}
.react-datepicker__navigation:focus, .react-datepicker__navigation:hover{
    outline: none;
}